import { maxGuesses, day1Number, dateToNumber, todayDayNum } from "./util";
import { Puzzle, GameState, gameDayStoragePrefix, guessesDayStoragePrefix, hardModeStoragePrefix, makePuzzle } from "./Game"

export interface Day
{
  puzzle: Puzzle,
  gameState: GameState,
  guesses: string[],
  hardMode: boolean
}

export function GetDay(date: Date) : Day | null {
  return GetDaynum(1 + dateToNumber(date) - day1Number);
}

export function GetDaynum(day: number) : Day | null 
{
  try {
    const resultKey = gameDayStoragePrefix+day;
    const guessesKey = guessesDayStoragePrefix+day;
    const hardModeKey = hardModeStoragePrefix+day;
    const storedState = window.localStorage.getItem(resultKey);
    const storedGuesses = window.localStorage.getItem(guessesKey);
    const storedHardMode = JSON.parse(window.localStorage.getItem(hardModeKey) ?? "false");
    let state = GameState.Playing;
    if (storedState) {
      state = JSON.parse(storedState);
    }
    if ( storedGuesses ) {
      return { 
        guesses: JSON.parse(storedGuesses), 
        puzzle: makePuzzle(day), 
        gameState: state, 
        hardMode: storedHardMode 
      };
    }
  } catch(e) {
  }
  return null;
}

export function RawStats() {

  let histogram: Record<number,number> = {};
  let streak: number = 0;
  let maxStreak: number = 0;
  let games: number = 0;
  let wins: number = 0;
  let maxHistogram : number = 0;

  for (let i = 1; i <= maxGuesses; ++i) {
    histogram[i] = 0;
  }

  for (let day: number = 1; day <= todayDayNum; ++day) 
  {
    let results = GetDaynum(day);
    
    if (!results) {
      streak = 0;
      continue;
    }

    games++;

    if (results.gameState === GameState.Lost) {
      streak = 0;
    }

    if (results.gameState === GameState.Won) {
      histogram[results.guesses.length]++;
      if (histogram[results.guesses.length] > maxHistogram) {
        maxHistogram = histogram[results.guesses.length];
      }
      streak++;
      wins++;
      if (streak > maxStreak) {
        maxStreak = streak;
      }
    }
  }

  return {
    histogram: histogram,
    streak: streak,
    maxStreak: maxStreak,
    games: games,
    wins: wins,
    maxHistogram: maxHistogram
  };
}

export function Stats() {

  let rawStats = RawStats();
  const isHttp = window.location.protocol === "http:";

  let styles : Record<number,Object> = {};  
  for (let key in rawStats.histogram) {
   styles[key] = { 'width' : Math.max( 7, Math.floor(100 * rawStats.histogram[key] / rawStats.maxHistogram) ) + '%', 'align' : 'right' };
  }

  return (
    <div className="Game-stats">
    <h1>games</h1>
    <div className="Game-stats-games">
      <div className="stat">
        <div className="stat-num">{rawStats.games}</div>
        <div className="stat-label"># Played</div>
      </div>
      <div className="stat">
        <div className="stat-num">{rawStats.games === 0 ? 0 : Math.floor(100*rawStats.wins/rawStats.games)}</div>
        <div className="stat-label">Win %</div>
      </div>
      <div className="stat">
        <div className="stat-num">{rawStats.streak}</div>
        <div className="stat-label">Streak</div>
      </div>
      <div className="stat">
        <div className="stat-num">{rawStats.maxStreak}</div>
        <div className="stat-label">Max Streak</div>
      </div>
    </div>
    <h1>guesses</h1>
    <div className="Game-stats-guesses">
      <div className="guess-stat"><div className="guess-count">1</div><div className="guess-graph"><div className="guess-bar" style={styles[1]}><div className="guess-games">{rawStats.histogram[1]}</div></div></div></div>
      <div className="guess-stat"><div className="guess-count">2</div><div className="guess-graph"><div className="guess-bar" style={styles[2]}><div className="guess-games">{rawStats.histogram[2]}</div></div></div></div>
      <div className="guess-stat"><div className="guess-count">3</div><div className="guess-graph"><div className="guess-bar" style={styles[3]}><div className="guess-games">{rawStats.histogram[3]}</div></div></div></div>
      <div className="guess-stat"><div className="guess-count">4</div><div className="guess-graph"><div className="guess-bar" style={styles[4]}><div className="guess-games">{rawStats.histogram[4]}</div></div></div></div>
      <div className="guess-stat"><div className="guess-count">5</div><div className="guess-graph"><div className="guess-bar" style={styles[5]}><div className="guess-games">{rawStats.histogram[5]}</div></div></div></div>
    </div>
    {isHttp && <div className="Game-stats-notice">Not seeing what you expect? Note that history is separate between secure (https) and non-secure sites (http). Maybe you meant to be on <a href="https://shuntle.com">https://shuntle.com</a></div>}
  </div>
  );
}

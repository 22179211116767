import { Clue, clueClass } from "./clue";

interface KeyboardProps {
  layout: string;
  letterInfo: Map<string, [Clue, number]>;
  onKey: (key: string) => void;
}

export function Keyboard(props: KeyboardProps) {
  const keyboard = props.layout
    .split("-")
    .map((row) =>
      row
        .split("")
        .map((key) => key
          .replace("B", "Backspace")
          .replace("E", "Enter")
          .replace("S", "Style")
          .replace("D","Delete")
          .replace("L","ArrowLeft")
          .replace("R","ArrowRight")
          .replace("_","SpaceBar")
          .replace("Z", "Sort"))
    );

  var c = 0;
  return (
    <div className="Game-keyboard" aria-hidden="true">
      {keyboard.map((row, i) => (
        <div key={i} className="Game-keyboard-row">
          {row.map((label, j) => {
            let className = "Game-keyboard-button";
            let typedKey = label;
            if (/^[a-z]$/i.test(typedKey)) {
              label = typedKey.toUpperCase();
              c++;
              typedKey = label[0].toLowerCase();
              className += " Letter-key";
              const clue = props.letterInfo.get(label.toLowerCase());
              if (clue !== undefined) {
                className += " " + clueClass(clue);
              }  
            } else {
              className += " Functional-key";
              label = label
                .replace("Backspace", "⌫")
                .replace("Style", "⇧")
                .replace("Sort", "⇄")
                .replace("SpaceBar", " ")
                .replace("Delete", "Del")
                .replace("ArrowLeft", "←")
                .replace("ArrowRight", "→");
            }
            return (
              <button
                tabIndex={-1}
                key={j}
                className={className}
                onClick={() => {
                  props.onKey(typedKey);
                }}
              >
                {label}
              </button>
            );
          })}
        </div>
      ))}
    </div>
  );
}

import { Puzzle } from "./Game";

export const hardCodedPuzzles: Record<number, Puzzle> = {
	"1": {
		"target": "  bloc "
	},
	"2": {
		"target": "decay  "
	},
	"3": {
		"target": " spare "
	},
	"4": {
		"target": " sheath"
	},
	"5": {
		"target": " revolt"
	},
	"6": {
		"target": "harper "
	},
	"7": {
		"target": " memoir"
	},
	"8": {
		"target": "cricket"
	},
	"9": {
		"target": " liner "
	},
	"10": {
		"target": "wooded "
	},
	"11": {
		"target": " fitch "
	},
	"12": {
		"target": "murphy "
	},
	"13": {
		"target": "tally  "
	},
	"14": {
		"target": "  save "
	},
	"15": {
		"target": " primer"
	},
	"16": {
		"target": "  photo"
	},
	"17": {
		"target": "telling"
	},
	"18": {
		"target": " ample "
	},
	"19": {
		"target": "whisper"
	},
	"20": {
		"target": " baking"
	},
	"21": {
		"target": "inmate "
	},
	"22": {
		"target": " buff  "
	},
	"23": {
		"target": " wager "
	},
	"24": {
		"target": " drake "
	},
	"25": {
		"target": " robe  "
	},
	"26": {
		"target": "  tape "
	},
	"27": {
		"target": " depth "
	},
	"28": {
		"target": "toast  "
	},
	"29": {
		"target": "laurel "
	},
	"30": {
		"target": " curve "
	},
	"31": {
		"target": "residue"
	},
	"32": {
		"target": "unjust "
	},
	"33": {
		"target": " ripple"
	},
	"34": {
		"target": "silesia"
	},
	"35": {
		"target": " theme "
	},
	"36": {
		"target": "  cent "
	},
	"37": {
		"target": "shaving"
	},
	"38": {
		"target": "melting"
	},
	"39": {
		"target": " living"
	},
	"40": {
		"target": " amuse "
	},
	"41": {
		"target": "chronic"
	},
	"42": {
		"target": "gesture"
	},
	"43": {
		"target": "speaker"
	},
	"44": {
		"target": "digital"
	},
	"45": {
		"target": "downing"
	},
	"46": {
		"target": "through"
	},
	"47": {
		"target": "somatic"
	},
	"48": {
		"target": "inquire"
	},
	"49": {
		"target": "nourish"
	},
	"50": {
		"target": "insular"
	}
}
import { useEffect, useRef, useState, useMemo } from "react";
import { Row, RowState } from "./Row";
import { Clue, clue, CluedLetter, describeClue } from "./clue";
import { Keyboard } from "./Keyboard";
import { PopUp } from "./PopUp"
import {
  gameName,
  pick,
  speak,
  practice,
  dayNum,
  todayDayNum,
  cheat,
  makeRandom,
  urlParam,
  isDev,
  needResetPractice,
  currentSeed,
  day1Date
} from "./util";

import { hardCodedPuzzles } from "./hardcoded";
import { hardCodedPractice } from "./hardcoded_practice";
import { Day, RawStats } from "./Stats"
import { readOnly, serializeStorage } from "./App";

import cheatyface from "./cheatyface.json";

import real_full_dictionary from "./dictionary.json";
import real_full_targets from "./targets.json";

const minWordLength = 4;
const maxWordLength = 7;

const full_dictionary = real_full_dictionary.filter(
  (word: string) => word.length >= minWordLength && word.length <= maxWordLength);

const full_targets = real_full_targets.filter(
  (word: string) => word.length >= minWordLength && word.length <= maxWordLength);

export enum GameState {
  Playing,
  Won,
  Lost,
}

declare const GoatEvent: Function;
declare const checkVersion: Function;

export const gameDayStoragePrefix = "DD1-result-";
export const gameDayResetsPrefix = "DD1-resets-";
export const hardModeStoragePrefix = "DD1-hard-mode-";
export const guessesDayStoragePrefix = "DD1-guesses-";

const eventKey = (practice 
  ? "Unlimited "
  : "Day "
) + currentSeed.toString();

function useLocalStorage<T>(
  key: string,
  initial: T
): [T, (value: T | ((t: T) => T)) => void] {
  const [current, setCurrent] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initial;
    } catch (e) {
      return initial;
    }
  });
  const setSetting = (value: T | ((t: T) => T)) => {
    try {
      const v = value instanceof Function ? value(current) : value;
      setCurrent(v);
      window.localStorage.setItem(key, JSON.stringify(v));
    } catch (e) {}
  };
  return [current, setSetting];
}

function useLocalStorageWriteImmediately<T>(
  key: string,
  initial: T
): [T, (value: T | ((t: T) => T)) => void] {
  const [current, setCurrent] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      if (!item) {
        window.localStorage.setItem(key, JSON.stringify(initial));
      }
      return item ? JSON.parse(item) : initial;
    } catch (e) {
      window.localStorage.setItem(key, JSON.stringify(initial));
      return initial;
    }
  });
  const setSetting = (value: T | ((t: T) => T)) => {
    try {
      const v = value instanceof Function ? value(current) : value;
      setCurrent(v);
      window.localStorage.setItem(key, JSON.stringify(v));
    } catch (e) {}
  };
  return [current, setSetting];
}

function gameOverText(state: GameState, target: string) : string {
  const verbed = state === GameState.Won ? "won!" : "lost.";
  return `You ${verbed} The word was ${target.toUpperCase().replaceAll(" ", "⎵")}.`; 
}

export async function copyImportCode() {
  try {
    await navigator.clipboard.writeText(serializeStorage());
    return;
  } catch (e) {
    console.warn("navigator.clipboard.writeText failed:", e);
  }
}

function loadPuzzle(seed: number) : Puzzle|undefined {
  let hardCoded = hardCodedPuzzles[seed];
  if (hardCoded && !practice) {
    if (full_dictionary.includes(hardCoded.target) ) {
      return hardCoded;
    }
    else {
      window.console.log("ERROR: " + hardCoded.target);
    }
  }
  hardCoded = hardCodedPractice[seed];
  if (hardCoded && practice) {
    if (full_dictionary.includes(hardCoded.target) ) {
      return hardCoded;
    }
    else {
      window.console.log("ERROR: " + hardCoded.target);
    }
  }
  return undefined;
}

function randomTarget(random: ()=>number, eligible: string[]): string {
  let candidate = pick(eligible, random);
  while (/\*/.test(candidate)) {
    candidate = pick(eligible, random);
  }
  while(candidate.length < 7) {
    candidate = random() < .5 ? " " + candidate : candidate + " ";
  }
  return candidate;
}

let uniqueGame = practice ? 600000 : 6000;
export function makePuzzle(seed: number) : Puzzle { 
  let loadedPuzzle = loadPuzzle(seed);
  if (loadedPuzzle) {
    return loadedPuzzle;
  }
  let random = makeRandom(seed+uniqueGame);

  const eligible = full_targets
    .slice(0, full_targets.indexOf("murky") + 1);

  let target = randomTarget(random, eligible);
  let puzzle: Puzzle = {
    target: target
  };
  return puzzle;
}

export function cluesEqual(clue1: CluedLetter[], clue2: CluedLetter[]) {
  if (clue1.length != clue2.length ) {
    return false;
  }
  for(var i = 0; i < clue1.length; ++i ) {
    if(clue1[i].clue != clue2[i].clue ) {
      return false;
    }
  }
  return true;
}

export function emojiBlock(day: Day, colorBlind: boolean) : string {
  const emoji = colorBlind
    ? ["⬛", "🟦", "🟧"]
    : ["⬛", "🟨", "🟩"];
  return day.guesses.map((guess) =>
        clue(guess, day.puzzle.target)
          .map((c) => emoji[c.clue ?? 0])
          .join("")
      )
      .join("\n");
}

export interface Puzzle {
  target: string
}

interface GameProps {
  maxGuesses: number;
  hidden: boolean;
  colorBlind: boolean;
  keyboardLayout: string;
  hardMode: boolean;
}

function Game(props: GameProps) {

  if (isDev && urlParam("export")) {
    let values : Record<number, Puzzle> = {};    
    for(let i = 1; i <= parseInt(urlParam("export") ?? "1"); ++i) {
      if (practice)
        continue;
      values[i] = makePuzzle(i);
    }
    window.console.log( JSON.stringify(values, null, "\t") );
  }

  const [puzzle, setPuzzle] = useState(() => {
    let p = makePuzzle(currentSeed);
    return p;
  });

  useEffect(() => {
    if(dayNum != 34 || practice)
      return;
    if(guesses.length == 0 && puzzle.target == "silesia") {
      puzzle.target = " sorry ";
      setResetCount((count) => count+1);
    }
    else if(resetCount > 0) {
      puzzle.target = " sorry ";
    }
  });
  
  let hardModeStorageKey = practice ? ("practiceHardMode") : (hardModeStoragePrefix+currentSeed);
  let stateStorageKey = practice ? ("practiceState") : (gameDayStoragePrefix+currentSeed);
  let resetVersionKey = practice ? ("practiceState") : (gameDayResetsPrefix+currentSeed);
  let guessesStorageKey = practice ? ("practiceGuesses") : (guessesDayStoragePrefix+currentSeed);

  const [gameState, setGameState] = useLocalStorage<GameState>(stateStorageKey, GameState.Playing);
  const [resetCount, setResetCount] = useLocalStorage<number>(resetVersionKey, 0);
  const [hardModeState, setHardModeState] = useLocalStorageWriteImmediately<boolean>(hardModeStorageKey, props.hardMode);
  const [guesses, setGuesses] = useLocalStorage<string[]>(guessesStorageKey, new Array(0));
  const [currentGuess, setCurrentGuess] = useState<[string,number]>(["       ",0]);
  const [hint, setHint] = useState<string>(getHintFromState());

  const canReset = isDev || 
      (dayNum == 34 && resetCount == 0 && puzzle.target == "silesia");

  const resetDay = () => {
    if (canReset) {
      window.localStorage.removeItem(gameDayStoragePrefix+dayNum);
      window.localStorage.removeItem(hardModeStoragePrefix+dayNum);
      window.localStorage.removeItem(guessesDayStoragePrefix+dayNum);
      setResetCount((count) => count+1);
    }
  }

  const resetPractice = () => {
    window.localStorage.removeItem("practice");
    window.localStorage.removeItem("practiceState");
    window.localStorage.removeItem("practiceHardMode");
    window.localStorage.removeItem("practiceGuesses");
  }

  if (needResetPractice) {
    resetPractice();
  }

  let Goat = (str: string) => {
    GoatEvent(str + (hardModeState ? ", Hard" : ""));
  };  
   
  const tableRef = useRef<HTMLTableElement>(null);
  async function share(copiedHint: string, text?: string) {
    
    if (window.location.protocol === "http:") {
      if (window.confirm(
        "Only secure websites (https, not http) can access the clipboard/sharing."
        + " Would you like to switch to the https site?"
        + " Note that game history is separate between http and https and you may need to update your bookmark."
      )) {
        window.location.protocol = "https:";
        return;
      }
    }

    const url = window.location.origin + window.location.pathname + (practice ? ("?unlimited=" + currentSeed.toString()) : "");
    const body = (text ? text + "\n" : "") + url;
    if (
      /android|iphone|ipad|ipod|webos/i.test(navigator.userAgent) &&
      !/firefox/i.test(navigator.userAgent)
    ) {
      try {
        await navigator.share({ text: body });
        return;
      } catch (e) {
        console.warn("navigator.share failed:", e);
      }
    }
    try {
      await navigator.clipboard.writeText(body);
      setHint(copiedHint);
      return;
    } catch (e) {
      console.warn("navigator.clipboard.writeText failed:", e);
    }
    setHint(url);
  }

  function getHintFromState() {    
    if  (gameState === GameState.Won || gameState === GameState.Lost) {
      return gameOverText(gameState, puzzle.target);
    }
    return "";
  }

  const onKey = (key: string) => {
    if (gameState !== GameState.Playing) {
      return;
    }

    const realMaxGuesses = props.maxGuesses;
  
    if (guesses.length === realMaxGuesses) {
      return;
    }
    if (/^[a-z]$/i.test(key)) {
      setCurrentGuess(([guess,cursor]) => {
        if ( cursor == -1 ) return [guess,cursor];
          return [
            (guess.slice(0,cursor) + key.toLowerCase() 
              + guess.slice(cursor+1)).slice(0, puzzle.target.length), 
              cursor == (puzzle.target.length-1) ? -1 : cursor+1]
        }        
      );
      tableRef.current?.focus();
      setHint(getHintFromState());
    } else if (key === "Backspace") {
      setCurrentGuess(([guess,cursor]) => {
        let realCursor = cursor == -1 ? puzzle.target.length : cursor;
        if (cursor != -1 && guess[cursor] != " " ) {
          // delete at the cursor and back up if the cursor is currently on a typed letter
          return [(guess.slice(0,cursor) + " " + guess.slice(cursor+1)).slice(0,puzzle.target.length),Math.max(0,realCursor-1)];
        }
        // if the cursor is on a space, delete behind the cursor and back up
        return [(guess.slice(0,realCursor-1) + " " + guess.slice(realCursor)).slice(0, puzzle.target.length),Math.max(0,realCursor-1)]
      });
      setHint(getHintFromState());
    } else if (key == "SpaceBar" || key == " ") {
      setCurrentGuess(([guess,cursor]) => {
        if ( cursor == -1 ) return [guess,cursor];
        return [(guess.slice(0,cursor) + " " + guess.slice(cursor+1)).slice(0, puzzle.target.length), 
          cursor == (puzzle.target.length-1) ? -1 : cursor+1]
      });
    } else if (key === "ArrowRight") {
      setCurrentGuess(([guess,cursor]) => [guess,cursor == (puzzle.target.length-1) ? 0 : cursor+1]);
    } else if (key === "ArrowLeft") {
      setCurrentGuess(([guess,cursor]) => [guess,cursor == -1 ? (puzzle.target.length-1) : cursor == 0 ? (puzzle.target.length-1) : cursor-1]);
    } else if (key === "Delete") {
      setCurrentGuess(([guess,cursor]) => [(guess.slice(0,cursor) + " " + guess.slice(cursor+1)).slice(0,puzzle.target.length),cursor]);
    } else if (key === "Enter") {
      let cg = currentGuess[0].trim();
      if (cg.length < minWordLength || cg.lastIndexOf(" ") !== -1) {
        setHint("Must include a word from " + minWordLength + " to " + maxWordLength + " letters");
        return;
      }
      if(guesses.includes(currentGuess[0])) {
        setHint("You've already guessed that");
        return;
      }
      if (!full_dictionary.includes(cg)) {
        Goat("Nonword: " + currentGuess);
        setHint(`Your guess must contain some valid word.`);
        return;
      }
      Goat("Guess " + (guesses.length+1) + ": " + currentGuess);
      setGuesses((guesses) => guesses.concat([currentGuess[0]]));
      setCurrentGuess(["       ",0]);
      speak(describeClue(clue(currentGuess[0], puzzle.target)))
      doWinOrLose();
    }
  };

  const doWinOrLose = () => {
    if ( gameState == GameState.Playing ) {      
      if ( (guesses.includes(puzzle.target)) ) {
        setGameState(GameState.Won);
        Goat("Won: " + eventKey + ", " + guesses.length + " guesses");
      } else if (guesses.length >= props.maxGuesses) {
        setGameState(GameState.Lost);
        Goat("Lost: " + eventKey);
      } 
    } 
    setHint(getHintFromState());
  };

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (!e.ctrlKey && !e.metaKey) {
        onKey(e.key);
      }
      if (!e.altKey && ["Backspace", "Delete", "SpaceBar", "ArrowLeft", "ArrowRight", " "].lastIndexOf(e.key) !== -1 && gameState == GameState.Playing) {
        e.preventDefault();
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [currentGuess, gameState]);

  useEffect(() => {
    doWinOrLose();
  }, [currentGuess, gameState, guesses, puzzle.target]);

  useEffect(() => {
    if ( gameState == GameState.Playing && guesses.length == 0 && currentGuess[0].length == 0 ) {
      Goat("Starting: " + eventKey);
    }
  }, [currentSeed]);
  
  const realMaxGuesses = gameState == GameState.Won 
    ? guesses.length 
    : Math.max(guesses.length, props.maxGuesses);

  let letterInfo = new Map<string, [Clue, number]>();

  const cluedRows = Array(realMaxGuesses)
    .fill(undefined)
    .map((_,i)=>{
      const guess = [...guesses, currentGuess[0]][i] ?? "";
      return clue(guess, puzzle.target); 
    });

  const tableRows = Array(realMaxGuesses)
    .fill(undefined)
    .map((_, i) => {      
      const cluedLetters = cluedRows[i];
      const lockedIn = i < guesses.length;
      const annotation = '\u00a0';
      if (lockedIn) {
        for (const { clue, letter } of cluedLetters) {
          if (clue === undefined) break;
          const count = cluedLetters
            .filter((cl) => cl.letter === letter && cl.clue && cl.clue > Clue.Absent)
            .length;
          const old = letterInfo.get(letter);
          const newCount = old === undefined || old[1] < count ? count : old[1];
          const newClue = old === undefined || old[0] < clue ? clue : old[0]; 
          letterInfo.set(letter, [newClue, newCount]);
        }
      }
      return (
        <Row
          onClick={(rowNumber, column) => {
            setCurrentGuess([currentGuess[0],column])
          }}
          currentGuess={currentGuess}
          key={i}         
          rowState={
            lockedIn
              ? RowState.LockedIn
              : (i === guesses.length)
              ? RowState.Editing
              : RowState.Pending
          }
          cluedRows={cluedRows.slice(undefined, guesses.length)}
          cluedLetters={cluedLetters}
          letterInfo={letterInfo}
          rowNumber={i}
          annotation={annotation}
          rowWidth={puzzle.target.length}
        />
      );
    });
    
  const dateText = `, ${new Date(day1Date.getTime()+(dayNum-1)*86400*1000).toLocaleDateString(undefined, { month: 'short', day: 'numeric' })}`;
  const cheatText = cheat ? ` ${puzzle.target}` : "";
  const canPrev = dayNum > 1;
  const canNext = dayNum < todayDayNum || isDev;
  const practiceLink = "?unlimited";
  const prevLink = "?x=" + (dayNum-1).toString() + (isDev ? "&xyzzyx="+cheatyface["password"] : "") + (cheat ? "&cheat=1" : "") + (urlParam("preventRedirect") !== null ? "&preventRedirect" : "");
  const nextLink = "?x=" + (dayNum+1).toString() + (isDev ? "&xyzzyx="+cheatyface["password"] : "") + (cheat ? "&cheat=1" : "") + (urlParam("preventRedirect") !== null ? "&preventRedirect" : "");

  const newsIndex = 9;
  const [readNewsItem, setReadNewsItem] = useLocalStorageWriteImmediately<number>("read-news-item", 0);
  const news: string = "";
  const showNews = news != "" && readNewsItem < newsIndex && RawStats().wins >= 1 && gameState == GameState.Playing;
  const hardModeIndicator = hardModeState ? " !!!" : "";
  
  return (
    <div className="Game" style={{ display: props.hidden ? "none" : "block" }}>
      {showNews && (<PopUp text={news} title={"News!"} onClick={ ()=>{setReadNewsItem(newsIndex); } }/>) }
      <div className="Game-options">
        {!practice && canPrev && <span><a className="NextPrev" href={prevLink}>«</a> </span>}
        {!practice && !canPrev && <span> <a className="NextPrev">&nbsp;</a></span>}
        {!practice && <span className="DayNum">Day {dayNum}{`${cheatText}`}{`${dateText}`}</span>}
        {!practice && canNext && <span> <a className="NextPrev" href={nextLink}>»</a></span>}
        {!practice && !canNext && <span> <a className="NextPrev">&nbsp;</a></span>}
        {canReset && <span>| <a href={window.location.href} onClick={ ()=>{resetDay();} }>Reset</a></span>}
        {practice && <span>{`${cheatText}`}</span>}
        {practice && <span>
          <a href=""
            className="practiceLink"
            onClick={(e) => {
              share(
                "Challenge link copied to clipboard!",
                ``
              );
              e.preventDefault();
            }}
          >
            Share
          </a>        
          <a href={practiceLink} className="practiceLink" onClick={ ()=>{resetPractice();} }> +New </a>
          </span>
        }
      </div>
      <table
        className="Game-rows"
        tabIndex={0}
        aria-label="table of guesses"
        ref={tableRef}
      >
        <tbody>{tableRows}</tbody>
      </table>
      <div
        role="alert"
        style={{
          userSelect: /https?:/.test(hint) ? "text" : "none",
          whiteSpace: "pre-wrap",
        }}
      >
        {(hint || `\u00a0`)}
        {gameState !== GameState.Playing && (
          <p>
          <button
            onClick={() => {
              const score = gameState === GameState.Lost ? "X" : guesses.length.toString();
              share(
                "Result copied to clipboard!",
                `${gameName} ${practice ? ("unlimited " + currentSeed.toString()) : ("#"+dayNum.toString())}${hardModeIndicator} ${score}/${props.maxGuesses}\n` +
                emojiBlock({guesses:guesses, puzzle:puzzle, gameState:gameState, hardMode:hardModeState}, props.colorBlind)
              );
            }}
          >
            Share
          </button>
          </p>
        )}      
      </div>
 
      {readOnly() && (
        <p>
        <button onClick={() => {copyImportCode();}} >
          Copy import code
        </button>
        </p>
      )}
      {!readOnly() && (
        <Keyboard
          layout={props.keyboardLayout}
          letterInfo={letterInfo}
          onKey={onKey}
        />)}
    </div>
  );
}

export default Game;
